<template>
  <div>
    <Banner
      v-if="!emailVerified"
      type="tozny-theme"
      buttonText="Resend"
      :clickMethod="resendVerificationEmail"
    >
      <h3 class="helvetica text-sm font-medium leading-snug mb-2">
        Verify Account Email
      </h3>
      <div class="leading-snug">
        Please verify your email by clicking the link we emailed you.
        Functionality is limited until verified.
      </div>
    </Banner>
    <Banner
      v-if="showTrialTimeRemaining"
      type="tozny-theme"
      buttonText="Upgrade"
      icon="query_builder"
      :clickMethod="routeToSubscription"
    >
      <h3 class="helvetica text-sm font-medium leading-snug mb-2">
        {{ daysLeftInFreeTrial }} Days Left In Trial
      </h3>
      <div class="leading-snug">
        You can upgrade to a Pay-As-You-Go subscription at any time without
        losing your free trial.
      </div>
      <template v-slot:additional-buttons>
        <NotificationsContactSalesButton />
      </template>
    </Banner>
    <Banner
      v-if="showTrialExpired"
      type="tozny-theme"
      buttonText="Upgrade"
      icon="query_builder"
      :clickMethod="routeToSubscription"
    >
      <h3 class="helvetica text-sm font-medium leading-snug mb-2">
        Your Trial Has Expired
      </h3>
      <div class="leading-snug">
        Please upgrade to a Pay-As-You-Go subscription to continue usage of
        Tozny products.
      </div>
      <template v-slot:additional-buttons>
        <NotificationsContactSalesButton />
      </template>
    </Banner>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Banner from '@/Common/Banner'
import NotificationsContactSalesButton from '@/Common/MainLayout/NotificationsContactSalesButton'
import { STRIPE_ENABLED } from '../../utils/utils.js'
export default {
  name: 'Notifications',
  components: {
    Banner,
    NotificationsContactSalesButton,
  },
  data() {
    return {
      stripeEnabled: false,
    }
  },
  mounted: function () {
    // eslint-disable-next-line
    this.stripeEnabled = STRIPE_ENABLED
  },
  methods: {
    ...mapActions('account', ['resendVerificationEmail']),
    ...mapActions(['pushRoute']),
    routeToSubscription: function () {
      this.pushRoute('/subscription')
    },
  },
  computed: {
    ...mapGetters('billing', [
      'billingStatus',
      'daysLeftInFreeTrial',
      'isTrial',
      'isFree',
    ]),
    ...mapGetters('account', ['emailVerified', 'payingCustomer']),
    showTrialExpired: function () {
      return (
        !this.isTrial &&
        !this.payingCustomer &&
        !this.isFree &&
        this.stripeEnabled
      )
    },
    showTrialTimeRemaining: function () {
      return (
        this.isTrial &&
        !this.payingCustomer &&
        !this.isFree &&
        this.stripeEnabled
      )
    },
  },
}
</script>
<style>
.helvetica {
  font-family: 'Helvetica Neue';
}
</style>
