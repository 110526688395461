<template>
  <nav class="font-display1 px-6">
    <ul class="w-40">
      <NavItem text="Dashboard" path="/" icon="dashboard" name="dashboard" />
      <NavDropdown
        title="TozStore"
        icon="icon-tozstore"
        group-name="tozstore"
        highlightClass="text-tozstore"
        :open="menuTozstoreOpen"
        @toggle="toggleTozstoreMenuOpen"
      >
        <template v-slot:dropdown-items>
          <NavItem
            text="Tokens"
            icon="gps_fixed"
            path="/tokens"
            name="tokens"
          />
          <NavItem
            text="Clients"
            icon="supervisor_account"
            path="/clients"
            name="clients"
          />
          <NavItem
            text="Groups"
            icon="folder_shared"
            path="/groups"
            name="groups"
          />
          <NavItem
            text="Advanced"
            icon="graphic_eq"
            path="/advanced"
            name="advanced"
          />
        </template>
      </NavDropdown>
      <NavDropdown
        title="Tozid"
        icon="icon-tozid"
        group-name="tozid"
        highlight-class="text-tozid"
        :open="menuTozidOpen"
        @toggle="toggleTozIdMenuOpen"
      >
        <template v-slot:dropdown-items>
          <NavItem
            text="Realms"
            icon="landscape"
            path="/identity/realms"
            name="realms"
          />
        </template>
      </NavDropdown>
      <NavDropdown
        title="Settings"
        icon="icon-settings"
        group-name="settings"
        highlight-class="text-tozny"
        :open="menuSettingsOpen"
        @toggle="toggleSettingsMenuOpen"
      >
        <template v-slot:dropdown-items>
          <NavItem
            text="Profile"
            icon="person"
            path="/profile"
            name="profile"
          />
          <NavItem
            v-if="stripeEnabled"
            text="Subscription"
            icon="credit_card"
            path="/subscription"
            name="subscription"
          />
        </template>
      </NavDropdown>
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import NavDropdown from './NavDropdown'
import NavItem from './NavItem'
import { STRIPE_ENABLED } from '../../utils/utils.js'
export default {
  name: 'NavMenu',
  components: {
    NavDropdown,
    NavItem,
  },
  data() {
    return {
      stripeEnabled: false,
    }
  },
  computed: {
    ...mapState('nav', [
      'menuTozstoreOpen',
      'menuTozidOpen',
      'menuSettingsOpen',
    ]),
    ...mapGetters('nav', ['activeNavItemPath']),
  },
  mounted: function () {
    // eslint-disable-next-line
    this.stripeEnabled = STRIPE_ENABLED
  },
  methods: {
    ...mapActions('nav', [
      'toggleTozstoreMenuOpen',
      'toggleTozIdMenuOpen',
      'toggleSettingsMenuOpen',
    ]),
  },
  // Lifecycle method opens the nav menu group for the currently active route on refresh
  created: function () {
    const firstGroup = this.$route.matched.find((r) => !!r.meta.menuGroup)
    if (
      firstGroup &&
      firstGroup.meta.menuGroup === 'tozstore' &&
      !this.menuTozstoreOpen
    ) {
      this.toggleTozstoreMenuOpen()
    } else if (
      firstGroup &&
      firstGroup.meta.menuGroup === 'tozid' &&
      !this.menuTozidOpen
    ) {
      this.toggleTozIdMenuOpen()
    } else if (
      firstGroup &&
      firstGroup.meta.menuGroup === 'settings' &&
      !this.menuSettingsOpen
    ) {
      this.toggleSettingsMenuOpen()
    }
  },
}
</script>
