<template>
  <section
    class="px-10 pt-10 clearfix bg-gray-lightest h-full ccolumn-items w-full"
  >
    <slot />
  </section>
</template>

<script>
export default {
  name: 'ContentColumn',
}
</script>
