<template>
  <li class="border-t border-b border-gray-light -mb-px">
    <a
      href="#!"
      @click.prevent="$emit('toggle')"
      class="flex items-center justify-between no-underline uppercase py-4"
      :title="title"
      :class="active ? highlightClass : 'text-gray-dark'"
    >
      <span class="flex">
        <slot name="dropdown-icon">
          <Icon :icon="icon" />
        </slot>
        <span class="font-medium ml-4">{{ title }}</span>
      </span>
      <i class="material-icons">{{ openIcon }}</i>
    </a>
    <transition name="slide">
      <ul v-show="open">
        <slot name="dropdown-items"></slot>
      </ul>
    </transition>
  </li>
</template>

<script>
import Icon from '@/Common/Icon'
export default {
  name: 'NavDropdown',
  components: {
    Icon,
  },
  props: {
    icon: String,
    title: String,
    highlightClass: String,
    open: Boolean,
    groupName: {
      type: String,
      required: true,
    },
  },
  computed: {
    openIcon() {
      return this.open ? 'expand_less' : 'expand_more'
    },
    active() {
      const firstGroup = this.$route.matched.find((r) => !!r.meta.menuGroup)
      return firstGroup && firstGroup.meta.menuGroup === this.groupName
    },
  },
}
</script>
<style scoped lang="scss">
.slide-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}
.slide-enter-active {
  transition: transform 0.3s 0.15s, opacity 0.3s 0.15s;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-5%);
  opacity: 0;
}
</style>
