<template>
  <li>
    <router-link
      class="flex items-center no-underline py-4"
      :to="path"
      :class="activeClass"
      :title="text"
    >
      <i class="material-icons micon">{{ icon }}</i>
      <span class="ml-4">{{ text }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'NavItem',
  computed: {
    activeClass: function () {
      const firstGroup = this.$route.matched.find((r) => !!r.meta.menu)
      const active = firstGroup && firstGroup.meta.menu === this.name
      return active ? 'text-gray-darkest' : 'text-gray-dark'
    },
  },
  props: {
    icon: String,
    text: String,
    path: String,
    name: String,
  },
}
</script>
