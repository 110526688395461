<template>
  <div class="shadow-md relative">
    <header class="p-3 constrained flex justify-between items-center">
      <img
        class="h-6 ml-4"
        alt="Tozny logo"
        src="@/assets/main-logo-green.svg"
      />
      <ToznyButton
        data-auto-id="logout-button"
        @click.native="logout"
        class="m-0 mr-4"
        buttonText="Logout"
      />
    </header>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'TitleBar',
  components: {
    ToznyButton,
  },
  methods: {
    ...mapActions('account', ['logout']),
  },
}
</script>
