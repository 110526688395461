<template>
  <svg :width="width" :height="height">
    <use :href="`#${icon}`" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
  },
}
</script>
