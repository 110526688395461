<template>
  <div class="min-h-screen flex flex-col">
    <TitleBar />
    <div class="flex constrained flex-grow w-full">
      <NavMenu />
      <main class="flex-grow min-w-0">
        <ContentColumn>
          <Notifications />
          <slot></slot>
        </ContentColumn>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import TitleBar from './TitleBar'
import NavMenu from './NavMenu'
import Notifications from './Notifications'
import ContentColumn from '@/Common/ContentColumn'
import Footer from '@/Common/Footer'
export default {
  name: 'MainLayout',
  components: {
    ContentColumn,
    Footer,
    NavMenu,
    Notifications,
    TitleBar,
  },
}
</script>
