<template>
  <div class="bg-gray-darkest h-full">
    <p class="text-white p-4 constrained">
      © {{ currentYear }} Tozny, Inc. All Rights Reserved
      <a href="https://tozny.com/tozny-terms-of-service/">Terms Of Use</a> |
      <a href="https://tozny.com/privacy-policy/">Privacy Policy</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear: function () {
      const currentDate = new Date()
      return currentDate.getFullYear()
    },
  },
}
</script>
